<div *ngIf="data.marginTopEnableWithBreadCrump==1">
    <div [ngClass]="{'margin-t10': this.data.enableBreadcrumbPages === 0, '': this.data.enableBreadcrumbPages !== 0}">

    </div>
</div>

<div fxLayout="row">




    <div fxFlex="20" fxHide fxShow.gt-sm fxFlex.xs="100" *ngIf="data.layout=='twentyseven'">
        <calsoft-sticky-menu-left></calsoft-sticky-menu-left>

    </div>
    <div fxFlex.xs="100" [fxFlex]="data.layout!='twentyseven'?100:80">
        <div *ngFor="let item of homePageProduct let i=index trackBy: trackByFn">

            <div [ngClass]="i>0 ? 'margin-t10' : ''">

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='TypingWithBanner'">
                    <calsoft-typing-banner [item]="item" [img]="item.images" [data]="data">

                    </calsoft-typing-banner>
                </div>
                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='livePrice'">
                    <calsoft-live-rate [item]="item" [img]="item.images" [data]="data"></calsoft-live-rate>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='360View'">
                    <calsoft-direction-view [item]="item"></calsoft-direction-view>
                </div>
                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='TextAnimationwithtext'">
                    <div *ngIf="item.customField17==0">
                        <calsoft-animated-text [item]="item" [img]="item.images" [data]="data"></calsoft-animated-text>
                    </div>
                </div>
                <div [ngClass]="i>0 ? 'margin-t11' : ''"
                    *ngIf="item.type=='TextAnimationwithtext' && item.customField17=='1' ">
                    <calsoft-textanimation2 [item]="item" [img]="item.images" [data]="data"></calsoft-textanimation2>
                </div>


                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='stepperform'">
                    <calsoft-step-form-main [item]="item" [img]="item.images" [data]="data"></calsoft-step-form-main>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='subscription'">
                    <calsoft-subscription [item]="item" [img]="item.images" [data]="data"></calsoft-subscription>
                </div>


                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='stepFlowLayout'">
                    <calsoft-stepflow-layout [item]="item" [img]="item.images" [data]="data"></calsoft-stepflow-layout>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='vedioBanner'">
                    <calsoft-vedio-banner-search [item]="item" [img]="item.images" [data]="data">
                    </calsoft-vedio-banner-search>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='partners'">
                    <calsoft-partners [item]="item" [img]="item.images" [data]="data"></calsoft-partners>
                </div>



                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='scrollableImageForm'">

                    <div fxLayout="row" class="container" fxLayout.xs="column">

                        <div fxFlex="50%" class="slider-wrapper">
                            <calsoft-sliderauto [item]="item" [img]="item.images" [data]="data"></calsoft-sliderauto>
                        </div>
                        <div fxFlex="50%">
                            <div *ngIf="item.customField30 == 2">
                                <app-common-login [data]="data" [item]="item" [img]="item.images"></app-common-login>
                            </div>
                            <div *ngIf="item.customField30 == 0 || item.customField30 == 1">
                                <calsoft-contact-form [data]="data" [item]="item" [input]="item.title">
                                </calsoft-contact-form>
                            </div>
                        </div>

                    </div>


                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='sliderImageWithText'">
                    <calsoft-slide-image-with-text [item]="item" [img]="item.images" [data]="data">
                    </calsoft-slide-image-with-text>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='serviceLayout'  && item.customField7=='1'">
                    <calsoft-service-layout [item]="item" [img]="item.images" [data]="data"></calsoft-service-layout>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='serviceLayout' && item.customField7=='2'">
                    <calsoft-imagecol-hover [item]="item" [img]="item.images" [data]="data"></calsoft-imagecol-hover>
                </div>
                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='serviceLayout' && item.customField7=='3'">
                    <calsoft-banner-search [item]="item" [img]="item.images" [data]="data"></calsoft-banner-search>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='serviceLayout' && item.customField7=='4'">

                    <calsoft-category-lists [item]="item" [img]="item.images" [data]="data"></calsoft-category-lists>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='serviceLayout' && item.customField7=='5'">
                    <calsoft-multiple-imagetext [item]="item" [img]="item.images" [data]="data">
                    </calsoft-multiple-imagetext>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='serviceLayout' && item.customField7=='6'">
                    <calsoft-image-circle-profile [item]="item" [img]="item.images" [data]="data">
                    </calsoft-image-circle-profile>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='serviceLayout' && item.customField7=='7'">
                    <calsoft-imagecol-hover2 [item]="item" [img]="item.images" [data]="data"></calsoft-imagecol-hover2>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='ScrollNavigationwithImage'">


                    <calsoft-scrolling-navigation [item]="item" [img]="item.images" [data]="data">
                    </calsoft-scrolling-navigation>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='dragdrop'">
                    <calsoft-kanbanform [item]="item" [img]="item.images" [data]="data">
                    </calsoft-kanbanform>
                </div>


                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='servicePlan'">
                    <div *ngIf="item.customField13==1 && item.customField13==2">
                        <calsoft-service-plan [data]="data" [item]="item" [heightMobile]="productImageHeightMob"
                            [widthMobile]="productImageWidthMob" [height]="productImageHeight"
                            [width]="productImageWidth" [imageType]="productImageType"
                            [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon"
                            [showCartIncremented]="showCartIncremented" [other]="'productScope'" [id]="id"
                            [sortOrder]="'price,DESC'" [img]="item.images" [layoutSub]="item.layoutSub">
                        </calsoft-service-plan>
                    </div>
                    <div *ngIf="item.customField13==3">
                        <calsoft-courseimage [item]="item" [layoutSub]="item.layoutSub" [img]="img">
                        </calsoft-courseimage>
                    </div>

                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='textWithText'">
                    <div [class.space]="item.customField8.length>0" [style.background-color]="item.customField8">
                        <div class="container">
                            <calsoft-text-with-text [data]="data" [item]="item" [img]="item.images">
                            </calsoft-text-with-text>
                        </div>
                    </div>
                </div>


                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='imageContainerWithText'">
                    <div class="container">
                        <calsoft-image-text-row [item]="item" [data]="data" [img]="item.images">
                        </calsoft-image-text-row>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='maplocation'">
                    <div class="container">
                        <calsoft-location-map-all></calsoft-location-map-all>
                    </div>
                </div>


                <div [ngClass]="i>0 ? 'margin-t11' : ''">
                    <div class="container"
                        *ngIf="item.type=='textwithform' && (item.layout == '0' || item.layout == '1') ">
               
                        <div [fxLayout.gt-md]="item.customField3=='left'?'row':'row-reverse'" fxLayout.xs="column"
                        [style.backgroundImage]="'url(' + serverPath+item.customField24 + ')'" [style.background-color]="item.customField37">
                            <div fxFlex="50" fxFlex.xs="100">
                                <calsoft-image-contact-form [item]="item" [data]="data" [img]="item.images">
                                </calsoft-image-contact-form>

                            </div>


                            <!-- <div *ngIf="item.customField3!='left'" fxFlex="5">

                            </div> -->

                            <!-- <span style="border-right: 1px solid lightgray; padding: 25px "></span> -->
                            <!-- <div *ngIf="item.customField3=='left'" fxFlex="5">

                            </div> -->
                            <div fxFlex="50" fxFlex.xs="100" fxLayout="column" [style.align-items]="item.customField33" [style.justify-content]="item.customField33"
                            style="display: flex; " >
                                <div style="background-color:white; margin: 10px;">
                                    <calsoft-contact-form [data]="data" [item]="item" [input]="item.title">
                                    </calsoft-contact-form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [fxLayout.gt-md]="item.customField3=='left'?'row':'row-reverse'" fxLayout.xs="column"
                        class="formcss" *ngIf="item.type=='textwithform' && item.layout == '2' ">
                  
                        <div fxFlex fxFlex.xs="100">
                            <calsoft-image-contact-form [data]="data" [item]="item" [img]="item.images">
                            </calsoft-image-contact-form>
                        </div>

                        <div fxFlex fxFlex.xs="100">
                            <calsoft-contact-form [data]="data" [item]="item" [input]="item.title">
                            </calsoft-contact-form>
                        </div>
                    </div>
                </div>

                <div [id]="item.customField35"
                    [ngClass]="{'margin-t10': i > 0, 'scroll-bg': item.customField9 && item.customField9.length > 0}"
                    *ngIf="item.type=='scrolltextwithform'"
                    [style.backgroundImage]="'url(' + serverPath+item.customField9 + ')'"
                    [ngStyle.gt-md]="{'height': item.customField32}" [style.background-color]="item.customField8">

                    <div class="container">
                        <div [fxLayout.gt-md]="item.customField3=='left'?'row':'row-reverse'" fxLayout.xs="column">
                            <div [fxFlex]="item.customField2!=undefined?item.customField2:'70'" fxFlex.xs="100"
                                [style.margin-top]="item.gapBetweenImage">

                                <div   fxLayout="column" 
                                style="height: 100%;" 
                                [fxLayoutAlign]="item.customField21.length > 0 ? '' : item.customField36" 
                                [ngStyle]="item.customField21.length > 0 ? { 'height': 'auto' } : { 'height': '100%' }">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <p [style.color]="item.customField33" class="newhead">
                                            <calsoft-editor-text [data]="data" [text]="item.customField6"
                                                [align]="item.customField34" [style.font-size]="item.customField37"
                                                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
                                            </calsoft-editor-text>
                                        </p>
                                    </div>

                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <p [style.color]="item.customField33" class="subpage">
                                            <calsoft-editor-text [data]="data" [text]="item.customField7"
                                                [align]="item.customField34" [style.font-size]="item.customField35"
                                                [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
                                            </calsoft-editor-text>
                                        </p>
                                    </div>

                                </div>
      
                                <div *ngIf="item.customField21.length>0" fxLayout="row" fxLayoutAlign="start center">
                                    <img [style.width]="item.height" onerror="" [style.height]="item.height"
                                        [ngStyle.lt-md]="{'height': item.heightMob}"
                                        [ngStyle.lt-md]="{'width': item.heightMob}"
                                        [src]="serverPath+item.customField21" />
                                </div>

                                <div *ngIf="item.customField29 === '0'" style="margin: 20px 0;">
                                    <button *ngIf="item.customField14.length>0" (click)="TextwithFormPopup(item)"
                                        [disabled]="item.isButtonDisabled" [style.padding]="'10px 20px'"
                                        [style.font-size]="'16px'" [style.color]="'#fff'" class="primary"
                                        [style.border]="'none'" [style.border-radius]="'5px'"
                                        [style.box-shadow]="'0 4px 6px rgba(0, 0, 0, 0.1)'" [style.cursor]="'pointer'"
                                        [style.opacity]="item.isButtonDisabled ? '0.6' : '1'"
                                        [style.transition]="'all 0.3s ease-in-out'" [style.transform]="'translateY(0)'"
                                        (mouseenter)="hovering = true" (mouseleave)="hovering = false"
                                        [style.transform]="hovering && !item.isButtonDisabled ? 'translateY(-2px)' : 'translateY(0)'">
                                        {{item.customField14}}
                                    </button>
                                </div>

                                <div *ngIf="item.layout!=0">
                                    <owl-carousel-o [options]="LogoSlider">
                                        <ng-container *ngFor="let img of item.images; let l = index">
                                            <ng-template carouselSlide>
                                                <div class="slide" width="100%" fxLayoutAlign="center center">
                                                    <img width="100%" fxLayoutAlign="center center"
                                                        [style.height]="item.customField11"
                                                        [ngStyle.lt-md]="{'height': item.customField12}"
                                                        [src]="serverPath1+img.img" />
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>


                            </div>

                            <div *ngIf="item.customField3!='left'" fxFlex="5">

                            </div>

                            <span *ngIf="!item.customField9 || item.customField9.length === 0"
                                style="border-right: 1px solid lightgray; padding: 25px "></span>

                            <div *ngIf="item.customField3=='left' && item.customField21.length!=0" fxFlex="5">

                            </div>


                            <!-- <div  [ngClass.lt-md]="'margin-t10'" [fxFlex]="item.customField2!=undefined?100-item.customField2:'30'" fxFlex.xs="100"  
                        [ngStyle.gt-md]="{'margin-top': item.gapBetweenImage, 'margin-bottom': '20px'}"
                        fxLayoutAlign="end center"> -->

                            <div [ngClass.lt-md]="'margin-t10'"
                                [fxFlex]="item.customField2!=undefined?100-item.customField2:'30'" fxFlex.xs="100"
                                [ngStyle]="{'margin-top': item.gapBetweenImage, 'margin-bottom': '20px'}">
                                <calsoft-contact-form [data]="data" [item]="item" [input]="item.title">
                                </calsoft-contact-form>
                            </div>



                        </div>

                        <div *ngIf="item.layout==0">
                            <div fxLayoutAlign="center center" class="title">
                                <h4 style="color: white;">
                                    <calsoft-editor-text [data]="data" [text]="item.customField4"
                                        [align]="item.customField34"
                                        [ngStyle.gt-md]="{'font-size': item.customField39 }"
                                        [ngStyle.lt-md]="{'font-size': item.customField40}">
                                    </calsoft-editor-text>
                                </h4>
                            </div>


                        </div>
                    </div>
                </div>

                <div *ngIf="item.type=='scrolltextwithform'&& item.customField23!=3" style="padding-top: 0;">
                    <div class="container">
                        <div [ngClass]="item.customField23==1 ? 'ph-ourClient-logo' : 'ph-ourClient-logo1'">
                            <owl-carousel-o [options]="LogoSlider1">
                                <ng-container *ngFor="let img of item.images; let l = index">
                                    <ng-template carouselSlide>
                                        <div class="slide" style="padding: 0 30px;" width="100%"
                                            fxLayoutAlign="center center">
                                            <img class="icons" fxLayoutAlign="center center"
                                                [style.height]="item.customField11"
                                                [ngStyle.lt-md]="{'height': item.customField12}"
                                                [src]="serverPath1+img.img" />
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>

                    </div>

                </div>





                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='categoryTabLayout'">
                    <div class="container">
                        <div [fxLayoutGap]="item.gapBetweenImage" fxLayout="row" fxLayout.lt-sm="column">
                            <div *ngFor="let images of item.images let d=index" fxFlex>
                                <calsoft-category-tab-layout [data]="data" [item]="item"
                                    [heightMobile]="productImageHeightMob" [widthMobile]="productImageWidthMob"
                                    [height]="productImageHeight" [width]="productImageWidth"
                                    [imageType]="productImageType" [showConfigurableDropdown]="showConfigurableDropdown"
                                    [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented"
                                    [other]="images.customField3=='Category'?'category' :'productScope'"
                                    [id]="images.customField3=='Category'?images.customField4:images.customField20"
                                    [sortOrder]="images.customField18" [header]="images.title" [item]="item"
                                    [images]="images" [img]="item.images"></calsoft-category-tab-layout>
                            </div>
                        </div>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='customGroupLayout'">
                    <div class="container">
                        <div [fxLayoutGap]="item.gapBetweenImage" fxLayout="row" fxLayout.lt-sm="column">
                            <div *ngFor="let images of item.images let d=index" fxFlex="100">
                                <calsoft-top-sellinglayout [data]="data" [item]="item"
                                    [heightMobile]="productImageHeightMob" [widthMobile]="productImageWidthMob"
                                    [height]="productImageHeight" [width]="productImageWidth"
                                    [imageType]="productImageType" [showConfigurableDropdown]="showConfigurableDropdown"
                                    [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented"
                                    [other]="images.customField3=='Category'?'category' :'productScope'"
                                    [id]="images.customField3=='Category'?images.customField4:images.customField20"
                                    [sortOrder]="images.customField18" [header]="images.title" [item]="item"
                                    [images]="images" [img]="item.images"></calsoft-top-sellinglayout>
                            </div>
                        </div>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='carouselwithProduct'">
                    <calsoft-carousel-with-product [data]="data" [item]="item" [heightMobile]="productImageHeightMob"
                        [widthMobile]="productImageWidthMob" [height]="productImageHeight" [width]="productImageWidth"
                        [imageType]="productImageType" [showConfigurableDropdown]="showConfigurableDropdown"
                        [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented"
                        [other]="item.type=='carouselwithProduct'?'productScope':'other'" [id]="item.customField1"
                        [sortOrder]="item.sortOrder" [header]="item.title" [item]="item" [img]="item.images">
                    </calsoft-carousel-with-product>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='tabLayout'">
                    <div [class.space]="item.customField8.length>0" class="bg-image"
                        [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                        [style.background-color]="item.customField8">
                        <div class="container">
                            <calsoft-tab-layout [data]="data" [item]="item" [heightMobile]="productImageHeightMob"
                                [widthMobile]="productImageWidthMob" [height]="productImageHeight"
                                [width]="productImageWidth" [imageType]="productImageType"
                                [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon"
                                [showCartIncremented]="showCartIncremented"
                                [other]="item.type=='carouselwithProduct'?'productScope':'other'"
                                [id]="item.customField1" [sortOrder]="item.sortOrder" [header]="item.title"
                                [item]="item" [img]="item.images">
                            </calsoft-tab-layout>
                        </div>

                    </div>

                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='customerReviews'">
                    <div class="container">
                        <calsoft-customerreviews [item]="item" [img]="item.images"></calsoft-customerreviews>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='storeLocations'">
                    <div class="container">
                        <calsoft-store-location [item]="item" [img]="item.images"></calsoft-store-location>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='iconTextWithBackground'">
                    <div [class.container]="item.customField23 !== '1'" class="container">
                        <calsoft-new-banner [data]="data" [item]="item" [img]="item.images"></calsoft-new-banner>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table'&& item.customField33=='1' ">
                    <div class="container">
                        <calsoft-price-chart-view fxFlex="100" [item]="item"></calsoft-price-chart-view>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && (item.customField33=='2' || item.customField33=='4') ">
                    <div class="container">
                        <calsoft-plan-compare fxFlex="100" [item]="item"></calsoft-plan-compare>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && item.customField33=='3' ">
                    <div class="container">
                        <calsoft-plan-compare-type2 fxFlex="100" [item]="item"></calsoft-plan-compare-type2>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && item.customField33=='5' ">
                    <div class="container">
                        <calsoft-plan-compare-type3 [type]="'1'" fxFlex="100" [item]="item">
                        </calsoft-plan-compare-type3>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && item.customField33=='6' ">
                    <div>
                        <calsoft-plan-compare-type4 fxFlex="100" [item]="item"></calsoft-plan-compare-type4>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && item.customField33=='7' ">
                    <div class="container">
                        <calsoft-plan-compare-type3 [type]="'2'" fxFlex="100" [item]="item">
                        </calsoft-plan-compare-type3>

                    </div>

                    <div [ngClass]="i>0 ? 'margin-t11' : ''">
                        <calsoft-subscription-price [item]="item" [type]="'1'"></calsoft-subscription-price>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && item.customField33=='12' ">
                    <div class="container">
                        <calsoft-plan-compare-type12 fxFlex="100" [item]="item">
                        </calsoft-plan-compare-type12>

                    </div>

                    <div [ngClass]="i>0 ? 'margin-t11' : ''">
                        <calsoft-subscription-price [item]="item" [type]="'1'"></calsoft-subscription-price>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && item.customField33=='9' ">
                    <div>
                        <calsoft-plan-compare-type5 fxFlex="100" [item]="item"></calsoft-plan-compare-type5>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && item.customField33=='10' ">
                    <div>
                        <calsoft-plan-compare-type10 fxFlex="100" [item]="item"></calsoft-plan-compare-type10>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" fxFlex="100"
                    *ngIf="item.type=='table' && item.customField33=='11' ">
                    <div>
                        <calsoft-plan-compare-type11 fxFlex="100" [item]="item"></calsoft-plan-compare-type11>
                    </div>
                </div>
    
                <ng-container *ngIf="item.type=='bannerTextWithButton'" style="display: contents;">

                    <div [class.space]="item.customField8.length>0" [ngClass]="i>0 ? 'margin-t11' : ''" class="bg-image"
                        [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                        [style.background-color]="item.customField8">
                        <div [ngClass]="i>0 ? 'margin-t11' : ''"
                            [class.container]="item.customField1 !== 'type3' && item.customField1 !== 'type4'"
                            class="container">
                            <calsoft-banner-over-content [item]="item" [data]="data" [img]="item.images">
                            </calsoft-banner-over-content>
                        </div>
                    </div>

                </ng-container>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='typeForm'">
                    <div [class.container]="item.customField20 !== 'type1'" class="container">
                        <calsoft-type-form [item]="item" [img]="item.images"></calsoft-type-form>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='partnerData'">
                    <div class="container">
                        <calsoft-partner-section [item]="item" [data]="data" [img]="item.images">
                        </calsoft-partner-section>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='categoryCarouselWithBanner'">
                    <div [class.container]="item.customField1 !== 'type4'" class="container">
                        <calsoft-categorywithimage [item]="item" [data]="data" [img]="item.images">
                        </calsoft-categorywithimage>
                    </div>
                </div>


                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='buttonType' && item.customField15=='4'">
                    <div class="container">
                        <calsoft-developmentservices [item]="item" [img]="item.images"></calsoft-developmentservices>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='mp4'">
                    <div class="container">
                        <calsoft-mp4 [item]="item" [img]="item.images"></calsoft-mp4>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='serviceContactButton'">
                    <div class="container">
                        <calsoft-service-contact-button [item]="item" [img]="item.images">
                        </calsoft-service-contact-button>
                    </div>
                </div>



                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='bannerVideo'">
                    <calsoft-vedio-banner [item]="item" [img]="item.images"></calsoft-vedio-banner>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='SearchWithBackground'">

                    <calsoft-search-with-bg [item]="item" [img]="item.images"></calsoft-search-with-bg>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='vedioBanner'&& item.customField25=='2'">
                    <calsoft-pdf-banner style="width: 100%" [item]="item" [fileName]="item.customField6">
                    </calsoft-pdf-banner>
                </div>


                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='slideImageText'">
                    <calsoft-image-over-content [item]="item" [img]="item.images"></calsoft-image-over-content>
                </div>


                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='footer'">
                    <calsoft-footerlayout [item]="item" [data]="data" [img]="item.images"></calsoft-footerlayout>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='TexthoverWithImage'">
                    <calsoft-texthoverwithimage [item]="item" [data]="data" [img]="item.images">
                    </calsoft-texthoverwithimage>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='faq'">
                    <div class="container" *ngIf="item.customField25!='2'">
                        <calsoft-faq [item]="item" [data]="data" [faq]="item.images"></calsoft-faq>
                    </div>

                    <div [fxLayout.gt-md]="item.customField3=='left'?'row':'row-reverse'" fxLayout="row"
                        fxLayout.xs="column" class="container" *ngIf="item.customField25=='2'">
                        <div fxFlex="50" fxLayout="column">
                            <div>

                                <img width="100%" [style.height]="item.height"
                                    [ngStyle.lt-md]="{'height': item.heightMob}" [src]="serverPath+item.customField6"
                                    [alt]="item.altImage || 'images'" />

                            </div>

                        </div>
                        <div fxFlex="50" fxLayout="column" [ngStyle]="{'margin-left': item.gapBetweenImage}">
                            <calsoft-faq [item]="item" [faq]="item.images"></calsoft-faq>
                        </div>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='scrollText'">
                    <div class="container">
                        <calsoft-freetext [item]="item"></calsoft-freetext>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='slideBanner'">
                    <calsoft-slidebanner [item]="item"></calsoft-slidebanner>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='bannertextWithImage'">
                    <calsoft-bannertext [item]="item"></calsoft-bannertext>
                </div>
                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='DoubleImagescroll'">
                    <calsoft-doubleimageform [data]="data" [item]="item"></calsoft-doubleimageform>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='categoryCarousel'">
                    <div class="container">
                        <calsoft-category-carousel [item]="item"></calsoft-category-carousel>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='buttonType' && item.customField15=='0'">
                    <div class="container">
                        <calsoft-button-type1 [item]="item"></calsoft-button-type1>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='buttonType' && item.customField15=='6'">
                    <div class="container">
                        <calsoft-button-type6 [item]="item"></calsoft-button-type6>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='buttonType' && item.customField15=='2'">
                    <calsoft-button-type2 [item]="item" [data]="data"></calsoft-button-type2>
                </div>

                <div *ngIf="item.images.length>0">
                    <div [ngClass]="i>0 ? 'margin-t11' : ''"
                        *ngIf="item.type=='brandsCarousel' && item.customField23!== '8' && item.customField23!== '26' && item.customField23!== '13' ">
                        <div [class.container]="item.customField23!== '6' && item.customField23!== '11'
                    
                     && item.customField23!== '12'&& item.customField23!== '22' "
                            [style.background-color]="item.customField8"
                            style="background-size: cover;"
                            [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                            [style.border-radius]="item.customField23 ? '10px' : '0px'">
                            <calsoft-brands [data]="data" [item]="item" [brands]="item.images"></calsoft-brands>
                        </div>

                    </div>
                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '8'">
                        <calsoft-carousel-slide [data]="data" [img]="item.images" [item]="item"></calsoft-carousel-slide>
                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '26'">
                        <calsoft-brands-carousel-type26 [img]="item.images" [data]="data" [item]="item">
                        </calsoft-brands-carousel-type26>
                    </div>

                    <div [ngClass]="i>0 ? 'margin-t11' : ''"
                        *ngIf="item.type=='brandsCarousel' && item.customField23== '13'">
                        <calsoft-brands [data]="data" [item]="item" [brands]="item.images"></calsoft-brands>
                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '15'">
                        <calsoft-banner-anime [img]="item.images" [data]="data" [item]="item"></calsoft-banner-anime>

                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '16'">
                        <calsoft-imagescrolling [img]="item.images" [data]="data" [item]="item">
                        </calsoft-imagescrolling>
                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '17'">
                        <calsoft-image-item-list [img]="item.images" [data]="data" [item]="item">
                        </calsoft-image-item-list>
                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '18'">
                        <div class="container">
                            <calsoft-anime-image fxFlex="100" [img]="item.images" [data]="data" [item]="item">
                            </calsoft-anime-image>
                        </div>
                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '19'">
                        <div class="container">
                            <calsoft-faciliteis-image fxFlex="100" [img]="item.images" [data]="data" [item]="item">
                            </calsoft-faciliteis-image>
                        </div>
                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '20'">
                        <div class="container">
                            <calsoft-image-checkbox fxFlex="100" [img]="item.images" [data]="data" [item]="item">
                            </calsoft-image-checkbox>
                        </div>
                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '23'">
                        <div class="container">
                            <calsoft-bannerimage-text2 fxFlex="100" [img]="item.images" [data]="data" [item]="item">
                            </calsoft-bannerimage-text2>
                        </div>
                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '24'">
                        <div [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'">
                            <div style="background-color: rgba(10, 15, 28, 0.7);">
                                <div class="container">
                                    <calsoft-pyramidimage fxFlex="100" [img]="item.images" [data]="data" [item]="item">
                                    </calsoft-pyramidimage>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="item.type=='brandsCarousel' && item.customField23== '28'">

                        <calsoft-brandscarousel-type27 fxFlex="100" [img]="item.images" [data]="data" [item]="item">
                        </calsoft-brandscarousel-type27>

                    </div>

                </div>



                <div *ngIf="item.type=='maps' && item.customField30 !== '1'" [ngClass]="i>0 ? 'margin-t11' : ''">

                    <div [ngClass]="item.layout=='2' ? 'container' :'' ">
                        <calsoft-maps-component [input]="item.title"></calsoft-maps-component>
                    </div>

                </div>


                <div *ngIf="item.type=='maps' && item.customField30 == '1' " [ngClass]="i>0 ? 'margin-t11' : ''">
                    <calsoft-location-map-all></calsoft-location-map-all>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" class="bg-image"
                    [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                    [style.background-color]="item.customField8" *ngIf="item.type=='contactForm'">
                    <div [ngClass]="item.layout=='2' ? 'container' :'' ">
                        <calsoft-contact-form [data]="data" [item]="item" [input]="item.title"></calsoft-contact-form>
                    </div>
                </div>

                <!-- <div [ngClass]="i>0 ? 'margin-t11' : ''" class="bg-image">
        <div class="container">
          <div fxLayout="row">
            <div fxFlex="50" style="border-right: 1px solid lightgray;padding: 25px ">
              <calsoft-image-contact-form></calsoft-image-contact-form>
            </div>
            <div fxFlex="50" style="padding: 25px">
              <calsoft-contact-form [data]="data" [item]="item" [input]="item.title"></calsoft-contact-form>
            </div>
          </div>
        </div>
      </div>       -->

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='blogData'">

                    <div [class.container]="item.layout !== '1'" class="container">
                        <calsoft-blog-section [data]="data"></calsoft-blog-section>
                    </div>
                </div>

                <div
                    *ngIf="item.type=='buttonType' && (item.customField15=='1' || item.customField15=='3' || item.customField15=='5' )">

                    <calsoft-button-row-header [data]="data" [item]="item"></calsoft-button-row-header>
                </div>

                <div [ngClass]="item.layout=='2' ? 'container' :'' ">


                    <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='mapsWithContactForm'">
                        <div fxLayout="row" fxLayout.xs="column">
                            <div fxFlex fxFlex.xs="100">
                                <calsoft-maps-component [input]="item.title"></calsoft-maps-component>
                            </div>
                            <div fxFlex fxFlex.xs="100">
                                <calsoft-contact-form [data]="data" [item]="item" [input]="item.customField4">
                                </calsoft-contact-form>
                            </div>

                        </div>

                    </div>
                </div>
                <ng-container
                    *ngIf="item.type=='category' || item.type=='productScope' || item.type=='otherCustomization'">
                    <!-- Added font Size fot category and product scope in cust 39 and 40 -->
                    <div>
                        <ng-container *ngIf="item.layout=='type-1'">
                            <div [ngClass]="i>0 ? 'margin-t11' : ''" class="bg-image"
                                [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                                [style.background-color]="item.customField8">
                                <div class="container padding-10">
                                    <calsoft-LighteningDeals [data]="data" [item]="item"
                                        [heightMobile]="productImageHeightMob" [widthMobile]="productImageWidthMob"
                                        [height]="productImageHeight" [width]="productImageWidth"
                                        [imageType]="productImageType"
                                        [showConfigurableDropdown]="showConfigurableDropdown"
                                        [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented"
                                        [other]="item.type=='otherCustomization'?'other':item.type"
                                        [id]="item.type=='productScope'?item.customField1:item.value"
                                        [sortOrder]="item.sortOrder" [header]="item.title">
                                    </calsoft-LighteningDeals>
                                </div>

                            </div>
                        </ng-container>

                        <ng-container *ngIf="item.layout=='type-2'">
                            <div [ngClass]="i>0 ? 'margin-t11' : ''" [class.space]="item.customField8.length>0"
                                class="bg-image" [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                                [style.background-color]="item.customField8">
                                <div class="container">
                                    <calsoft-product-carousel [heightMobile]="productImageHeightMob" [item]="item"
                                        [widthMobile]="productImageWidthMob" [data]="data" [height]="productImageHeight"
                                        [width]="productImageWidth" [imageType]="productImageType"
                                        [showConfigurableDropdown]="showConfigurableDropdown"
                                        [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented"
                                        [other]="item.type=='otherCustomization'?'other':item.type"
                                        [id]="item.type=='productScope'?item.customField1:item.value"
                                        [sortOrder]="item.sortOrder" [header]="item.title" [item]="item">
                                    </calsoft-product-carousel>
                                </div>

                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.layout=='type-3'">
                            <div [ngClass]="i>0 ? 'margin-t11' : ''" [class.space]="item.customField8.length>0"
                                class="bg-image" [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                                [style.background-color]="item.customField8">
                                <div class="container">
                                    <calsoft-TopProducts [heightMobile]="productImageHeightMob"
                                        [widthMobile]="productImageWidthMob" [data]="data" [height]="productImageHeight"
                                        [width]="productImageWidth" [imageType]="productImageType"
                                        [showConfigurableDropdown]="showConfigurableDropdown"
                                        [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented"
                                        [other]="item.type=='otherCustomization'?'other':item.type"
                                        [id]="item.type=='productScope'?item.customField1:item.value"
                                        [sortOrder]="item.sortOrder" [item]="item" [header]="item.title">
                                    </calsoft-TopProducts>
                                </div>
                            </div>
                        </ng-container>
                    </div>


                </ng-container>

                <ng-container *ngIf="item.type=='banner'" style="display: contents;">

                    <div [ngClass]="i>0 ? 'margin-t11' : ''" class="bg-image"
                        [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                        [style.background-color]="item.customField8">
                        <div [ngClass]="i>0 ? 'margin-t11' : ''"
                            [ngClass]="item.customField1=='type1' || item.customField1=='type12' || item.customField1=='type13' ? '' : 'container'">

                            <calsoft-home-banner-layout [bannerHeight]="bannerHeight" [bannerWidth]="bannerWidth"
                                [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                                [bannerTabletHeight]="bannerTabletHeight" [bannerTabletWeight]="bannerTabletWeight"
                                [item]="item" [img]="item.images"></calsoft-home-banner-layout>
                            <!-- <calsoft-carousel [type]="'common'"  [category]="item.customField2" [layout]="item.images"></calsoft-carousel> -->
                            <!-- <app-homepagetwoslider [item]="item.images"></app-homepagetwoslider> -->
                        </div>

                    </div>

                </ng-container>



                <div [style.marginTop]="item.customField35" class="bg-image"
                    *ngIf="item.type=='image' && item.customField10 !== 'Type 11' ">

                    <div [id]="item.id" [style.background-color]="item.customField8"
                        [fxLayoutGap]="item.mobileGapBetweenImage">
                        <span class="imgtitle" *ngIf="item.customField5">


                            <calsoft-editor-text [data]="data" [text]="item?.customField5"
                                [ngStyle.gt-md]="{'font-size': item.customField18 }" [ngStyle.lt-md]="{
                            
                            'font-size': item.customField17
                        }">
                            </calsoft-editor-text>
                        </span>

                        <div class="bg-image" [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                            [style.background-color]="item.customField8">
                            <div class="container" [ngClass]="item.customField10=='type1' ? '' : 'sas1'"
                                [fxLayout]="item.customField10 == 'Type 10' ? 'row' : 'row wrap'" fxFlex="100">
                                    
                                <div *ngFor="let img of item.images;let i=index"
                                    [ngStyle]="{ 'margin-right':item.images.length-1==i?'0px':item.gapBetweenImage}"
                                    [ngStyle.lt-md]="{
                    'margin-right': (item.images.length-1==i?'0px':item.displayMobileImage === 1 || 
                                     (item.displayMobileImage === 3 && i > 1)) ? '0px' : item.mobileGapBetweenImage
                  }" [fxFlex]="item.layout === 2 ? '100%' : 'auto'" [fxFlex]="item.layout == 2 ? 
               (item.gapBetweenImage ?  'calc(50% - ' + item.gapBetweenImage + ')' : '50%') 
               : (item.layout == 3 ? (item.gapBetweenImage ? 'calc(33.33% - ' + item.gapBetweenImage + ')' : '33.33%') 
                 : (item.layout == 4 ? (item.gapBetweenImage ? 'calc(25% - ' + item.gapBetweenImage + ')' : '25%') 
                   : '100'))" [fxFlex.lt-md]="item.displayMobileImage == 2 ? (item.mobileGapBetweenImage ? 'calc(50% - ' + item.mobileGapBetweenImage + ')' : '50%')
                   : (item.displayMobileImage == 3 ? (item.mobileGapBetweenImage ? 'calc(33.33% - ' + item.mobileGapBetweenImage + ')' : '33.33%')
                     : (item.displayMobileImage == 4 ? (item.mobileGapBetweenImage ? 'calc(25% - ' + item.mobileGapBetweenImage + ')' : '25%')
                       : '100'))" class=" margin-t10 images">


                                    <div *ngIf="img.type==2" fxLayoutAlign="center center">
                                        <div [style.height]="item.height" [ngStyle.lt-md]="{
              'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : 
              i == 0 ? '10px' : '0px' : '0px',
              'height': item.heightMob
               }" fxLayout="row">
                                            <div style="height:100%;"
                                                [style.width]="img.customField22 ? img.customField22 : '100%'"
                                                [ngStyle.lt-md]="{ 'width': img.customField24}"
                                                [ngStyle.lt-md]="{'width':'100%'}">
                                                <span *ngIf="img.autoplay == 1">
                                                    <iframe oncanplay="this.play()" style="width: 100%; height: 100%"
                                                        [src]="youtubeUrl + img.img + '?autoplay=1' | safe: 'resourceUrl'"
                                                        frameborder="0" allow="autoplay">
                                                    </iframe>
                                                </span>
                                                <span *ngIf="img.autoplay == 0">
                                                    <iframe style="width: 100%; height: 100%"
                                                        [src]="youtubeUrl + img.img | safe: 'resourceUrl'">
                                                    </iframe>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div *ngIf="img.type==1 && item.customField10 !== 'Type 3' && item.customField10 !== 'Type 4' && item.customField10 != 'Type 6'  && item.customField10 != 'Type 8' && item.customField10 != 'Type 9' && item.customField10 != 'Type 10'"
                                        fxLayoutAlign="center center">

                                        <div fxFlex contentAnim [aniName]="img.customField7"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast">
                                            <div fxLayout="column"
                                                [ngClass]="item.customField10== 'Type 7' ? 'image-container' : ''">
                                                <div class="pointer" fxLayout="column">
                                                    <img width="100%" [style.height]="item.height" [ngStyle.lt-md]="{
                        'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : 
                        i == 0 ? '10px' : '0px' : '0px',
                        'height': item.heightMob
                    }" [ngClass]="item.customField9==2 ? '' : 'collection-banner '"
                                                        fxLayoutAlign="space-evenly stretch" (click)="(img.navigation == 0) ? imageNavigation(img.catId) 
                                                    : (img.navigation == 1) ? link(img.navigatedUrl) 
                                                    : (img.navigation == 2) ? dynamicFormPopup(img.catId) 
                                                    : (img.navigation == 3) ? scroll(item.customField35) 
                                                    : null" [src]="serverPath+img.img" [alt]="img.altImage || 'images'"
                                                        loading="lazy">
                                                </div>

                                                <div fxLayout="row" fxLayout.xs="column"
                                                    *ngIf="item.customField10 == 'Type 7'">
                                                    <div class="bottom-content">
                                                        <div fxFlex="50">
                                                            <h4 [style.font-size]="item.customField18"
                                                                [ngStyle.lt-md]="{ 'font-size': item.customField17 }"
                                                                style="text-align: left;padding-left: 20px;">{{
                                                                img.customField1 }}</h4>
                                                        </div>
                                                        <div fxFlex="50" fxLayoutAlign="end center">
                                                            <ng-container *ngIf="img.customField12">
                                                                <button (click)="(img.navigation == 0) ? imageNavigation(img.catId) 
                                                        : (img.navigation == 1) ? link(img.navigatedUrl) 
                                                        : (img.navigation == 2) ? dynamicFormPopup(img.catId) 
                                                        : (img.navigation == 3) ? scroll(item.customField35) 
                                                        : null" [style.color]="'#ffffff'"
                                                                    [style.border-radius]="img.customField11"
                                                                    class="primary padding-tb10 image-button">
                                                                    {{img.customField12}}
                                                                </button>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="item.customField10 !== 'Type 7'">
                                                    <div fxLayout="column">
                                                        <span *ngIf="img.customField1 !== null">
                                                            <span *ngIf="img.customField1.length>0"
                                                                fxLayoutAlign="space-evenly center" fxLayoutGap="5px">
                                                                <p [style.font-size]="item.customField18"
                                                                    [ngStyle.lt-md]="{ 'font-size': item.customField17 }">
                                                                    {{
                                                                    img.customField1 }}
                                                                </p>
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div fxLayout="column">
                                                        <div
                                                            [ngStyle]="{'display': img.customField12 && img.customField12.length === 0 ? 'none' : 'block'}">
                                                            <div *ngIf="img.customField13==0"
                                                                fxLayoutAlign="start center">
                                                                <ng-container *ngIf="img.customField12">
                                                                    <button (click)="(img.navigation == 0) ? imageNavigation(img.catId) 
                                                            : (img.navigation == 1) ? link(img.navigatedUrl) 
                                                            : (img.navigation == 2) ? dynamicFormPopup(img.catId) 
                                                            : (img.navigation == 3) ? scroll(item.customField35) 
                                                            : null" [style.color]="'#ffffff'"
                                                                        [style.border-radius]="img.customField11"
                                                                        class="primary padding-tb10" mat-flat-button>
                                                                        {{img.customField12}}
                                                                    </button>
                                                                </ng-container>
                                                            </div>

                                                            <div *ngIf="img.customField13==1"
                                                                fxLayoutAlign="center center">
                                                                <ng-container *ngIf="img.customField12">
                                                                    <button (click)="(img.navigation == 0) ? imageNavigation(img.catId) 
                                                            : (img.navigation == 1) ? link(img.navigatedUrl) 
                                                            : (img.navigation == 2) ? dynamicFormPopup(img.catId) 
                                                            : (img.navigation == 3) ? scroll(item.customField35) 
                                                            : null" [style.color]="'#ffffff'"
                                                                        [style.border-radius]="img.customField11"
                                                                        class="primary padding-tb10" mat-flat-button>
                                                                        {{img.customField12}}
                                                                    </button>
                                                                </ng-container>
                                                            </div>
                                                            <div *ngIf="img.customField13==2">
                                                                <ng-container *ngIf="img.customField12">
                                                                    <button fxFlex (click)="(img.navigation == 0) ? imageNavigation(img.catId) 
                                                            : (img.navigation == 1) ? link(img.navigatedUrl) 
                                                            : (img.navigation == 2) ? dynamicFormPopup(img.catId) 
                                                            : (img.navigation == 3) ? scroll(item.customField35) 
                                                            : null" [style.color]="'#ffffff'"
                                                                        [style.border-radius]="img.customField11"
                                                                        class="primary padding-tb10" mat-flat-button>
                                                                        {{img.customField12}}
                                                                    </button>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="img.type==1 && item.customField10 == 'Type 3'"
                                        fxLayoutAlign="center center">


                                        <div fxFlex contentAnim [aniName]="img.customField7"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast">
                                            <div fxLayout="column">
                                                <div fxLayout="column">
                                                    <div class="crosseffect"
                                                        (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)">
                                                        <a>
                                                            <img width="100%" [style.height]="item.height"
                                                                [ngStyle.lt-md]="{
                        'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : 
                        i == 0 ? '10px' : '0px' : '0px',
                        'height': item.heightMob
                    }" [ngClass]="item.customField9==2 ? '' : 'collection-banner '"
                                                                fxLayoutAlign="space-evenly stretch"
                                                                (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                [src]="serverPath+img.img"
                                                                [alt]="img.altImage || 'images'" loading="lazy">
                                                        </a>
                                                    </div>
                                                </div>

                                                <div fxLayout="column">
                                                    <span *ngIf="img.customField1 !== null">
                                                        <span *ngIf="img.customField1.length>0"
                                                            fxLayoutAlign="space-evenly center" fxLayoutGap="5px">
                                                            <p [style.font-size]="item.customField18"
                                                                [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{
                                                                img.customField1 }}
                                                            </p>
                                                        </span>
                                                    </span>
                                                </div>

                                                <div fxLayout="column">
                                                    <div
                                                        [ngStyle]="{'display': img.customField12 && img.customField12.length === 0 ? 'none' : 'block'}">
                                                        <div *ngIf="img.customField13==0" fxLayoutAlign="start center">
                                                            <ng-container *ngIf="img.customField12">
                                                                <button
                                                                    (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                    [style.color]="'#ffffff'"
                                                                    [style.border-radius]="img.customField11"
                                                                    class="primary padding-tb10" mat-flat-button>
                                                                    {{img.customField12}}
                                                                </button>
                                                            </ng-container>
                                                        </div>

                                                        <div *ngIf="img.customField13==1" fxLayoutAlign="center center">
                                                            <ng-container *ngIf="img.customField12">
                                                                <button
                                                                    (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                    [style.color]="'#ffffff'"
                                                                    [style.border-radius]="img.customField11"
                                                                    class="primary padding-tb10" mat-flat-button>
                                                                    {{img.customField12}}
                                                                </button>
                                                            </ng-container>
                                                        </div>
                                                        <div *ngIf="img.customField13==2">
                                                            <ng-container *ngIf="img.customField12">
                                                                <button fxFlex
                                                                    (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                    [style.color]="'#ffffff'"
                                                                    [style.border-radius]="img.customField11"
                                                                    class="primary padding-tb10" mat-flat-button>
                                                                    {{img.customField12}}
                                                                </button>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="brighten" *ngIf="img.type==1 && item.customField10 == 'Type 4'"
                                        fxLayoutAlign="center center">

                                        <div fxFlex contentAnim [aniName]="img.customField7"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast brighten">
                                            <div fxLayout="column" style="position: relative; cursor: pointer;">
                                                <div fxLayout="column" class="brighten">
                                                    <div class="brighten" style="position: relative; cursor: pointer;">
                                                        <img width="100%" [style.height]="item.height" [ngStyle.lt-md]="{
                              'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : i == 0 ? '10px' : '0px' : '0px',
                              'height': item.heightMob
                            }" [ngClass]="item.customField9==2 ? '' : 'collection-banner '"
                                                            fxLayoutAlign="space-evenly stretch"
                                                            (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                            [src]="serverPath+img.img" [alt]="img.altImage || 'images'"
                                                            loading="lazy">


                                                        <div class="brighten"
                                                            style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                                            <p style="color: white;"
                                                                [style.font-size]="item.customField18"
                                                                [ngStyle.lt-md]="{ 'font-size': item.customField17 }">
                                                                {{ img.customField1 }}
                                                            </p>

                                                            <div>
                                                                <br>
                                                            </div>

                                                            <div [ngStyle]="{'display': img.customField12 && img.customField12.length === 0 ? 'none' : 'block'}"
                                                                style="text-align: center;">
                                                                <div *ngIf="img.customField13==0"
                                                                    style="display: inline-block;">
                                                                    <ng-container *ngIf="img.customField12">
                                                                        <button
                                                                            (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                            [style.color]="'#ffffff'"
                                                                            [style.border-radius]="img.customField11"
                                                                            class="primary padding-tb10"
                                                                            mat-flat-button>
                                                                            {{img.customField12}}
                                                                        </button>
                                                                    </ng-container>
                                                                </div>
                                                                <div *ngIf="img.customField13==1"
                                                                    style="display: inline-block;">
                                                                    <ng-container *ngIf="img.customField12">
                                                                        <button
                                                                            (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                            [style.color]="'#ffffff'"
                                                                            [style.border-radius]="img.customField11"
                                                                            class="primary padding-tb10"
                                                                            mat-flat-button>
                                                                            {{img.customField12}}
                                                                        </button>
                                                                    </ng-container>
                                                                </div>
                                                                <div *ngIf="img.customField13==2"
                                                                    style="display: inline-block;">
                                                                    <ng-container *ngIf="img.customField12">
                                                                        <button fxFlex
                                                                            (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                            [style.color]="'#ffffff'"
                                                                            [style.border-radius]="img.customField11"
                                                                            class="primary padding-tb10"
                                                                            mat-flat-button>
                                                                            {{img.customField12}}
                                                                        </button>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="brighten" *ngIf="img.type==1 && item.customField10 == 'Type 6'"
                                        fxLayoutAlign="center center">

                                        <div fxFlex contentAnim [aniName]="img.customField7"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast brighten">
                                            <div fxLayout="column" style="position: relative; cursor: pointer;">
                                                <div fxLayout="column" class="brighten">
                                                    <div class="newimg" (click)="popupDialog(serverPath+img.img)"
                                                        style="position: relative; cursor: pointer;">
                                                        <img width="100%" [style.height]="item.height" [ngStyle.lt-md]="{
                          'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : i == 0 ? '10px' : '0px' : '0px',
                          'height': item.heightMob
                        }" [ngClass]="item.customField9==2 ? '' : 'collection-banner '"
                                                            fxLayoutAlign="space-evenly stretch"
                                                            (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                            [src]="serverPath+img.img" [alt]="img.altImage || 'images'"
                                                            loading="lazy">

                                                        <!-- 
                                                    <div class="brighten"
                                                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                                        <p style="color: white;" [style.font-size]="item.customField18"
                                                            [ngStyle.lt-md]="{ 'font-size': item.customField17 }">
                                                            {{ img.customField1 }}
                                                        </p>

                                                        <div>
                                                            <br>
                                                        </div>

                                                        <div [ngStyle]="{'display': img.customField12 && img.customField12.length === 0 ? 'none' : 'block'}"
                                                            style="text-align: center;">
                                                            <div *ngIf="img.customField13==0"
                                                                style="display: inline-block;">
                                                                <button
                                                                    (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                    [style.color]="'#ffffff'"
                                                                    [style.border-radius]="img.customField11"
                                                                    class="primary padding-tb10" mat-flat-button>
                                                                    {{img.customField12}}
                                                                </button>
                                                            </div>
                                                            <div *ngIf="img.customField13==1"
                                                                style="display: inline-block;">
                                                                <button
                                                                    (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                    [style.color]="'#ffffff'"
                                                                    [style.border-radius]="img.customField11"
                                                                    class="primary padding-tb10" mat-flat-button>
                                                                    {{img.customField12}}
                                                                </button>
                                                            </div>
                                                            <div *ngIf="img.customField13==2"
                                                                style="display: inline-block;">
                                                                <button fxFlex
                                                                    (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                                                                    [style.color]="'#ffffff'"
                                                                    [style.border-radius]="img.customField11"
                                                                    class="primary padding-tb10" mat-flat-button>
                                                                    {{img.customField12}}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div> -->


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>





                                    <div *ngIf="img.type==3">

                                        <div contentAnim [aniName]="img.customField7" class="fast">

                                            <calsoft-vedio-layout fxFlex="100" [index]="i" [item]="item" [img]="img">
                                            </calsoft-vedio-layout>
                                        </div>
                                    </div>

                                    <div *ngIf="img.type==4" fxFlex="100" fxLayoutAlign="center center">

                                        <div fxFlex contentAnim [aniName]="img.customField7"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast">
                                            <calsoft-image-over-content [index]="i" [item]="item" [img]="img">
                                            </calsoft-image-over-content>
                                        </div>

                                    </div>


                                    <div *ngIf="img.type==12" fxFlex="100" fxLayoutAlign="center center">

                                        <div fxFlex contentAnim [aniName]="img.customField7"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast">
                                            <calsoft-image-over-content2 [index]="i" [item]="item" [img]="img">
                                            </calsoft-image-over-content2>
                                        </div>

                                    </div>

                                    <div *ngIf="img.type==5" fxFlex="100" >
                                        <calsoft-image-card [index]="i" [item]="item" [img]="img"></calsoft-image-card>
                                    </div>

                                    <div *ngIf="img.type==6" fxFlex="100" fxLayoutAlign="center center">
                                        <calsoft-lookback fxFlex="100" [data]="data" [index]="i" [item]="item"
                                            [img]="img">
                                        </calsoft-lookback>
                                    </div>

                                    <div *ngIf="img.type==7" fxFlex="100" fxLayoutAlign="center center">
                                        <div fxFlex="100"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast">
                                            <calsoft-image-text-with-button fxFlex="100" [data]="data" [index]="i"
                                                [item]="item" [img]="img"></calsoft-image-text-with-button>
                                        </div>

                                    </div>

                                    <div *ngIf="img.type==8 && item.customField10 != 'Type 8' && img.customField33!=11"
                                        fxFlex="100" fxLayoutAlign="center start" style="align-items:start;">

                                        <div fxFlex="100">
                                            <calosft-mobileicon [data]="data" [index]="i" [item]="item" [img]="img">
                                            </calosft-mobileicon>
                                        </div>

                                    </div>

                                    <div *ngIf="img.type==9 " fxFlex="100" fxLayoutAlign="center center">
                                        <div fxFlex="100"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast">
                                            <calsoft-image-text fxFlex="100" [data]="data" [index]="i" [item]="item"
                                                [img]="img"></calsoft-image-text>
                                        </div>

                                    </div>

                                    <div *ngIf="img.type==8 && img.customField33==11 " fxFlex="100"
                                        fxLayoutAlign="center center">
                                        <div fxFlex="100">
                                            <calsoft-iconflow fxFlex="100" [data]="data" [index]="i" [item]="item"
                                                [img]="img" [totalItems]="item.images.length"></calsoft-iconflow>
                                        </div>

                                    </div>

                                    <div *ngIf="img.type==10" fxFlex="100" fxLayoutAlign="center center">
                                        <div fxFlex="100"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast">
                                            <calsoft-scroll-image fxFlex="100" [data]="data" [index]="i" [item]="item"
                                                [img]="img"></calsoft-scroll-image>
                                        </div>

                                    </div>

                                    <div *ngIf="img.type==11" fxFlex="100" fxLayoutAlign="center center">
                                        <div fxFlex="100"
                                            [ngClass]="{'border':item.customField10=='Type 2', 'collection-banner': item.customField9==1}"
                                            class="fast">
                                            <calsoft-price-plan fxFlex="100" [data]="data" [index]="i" [item]="item"
                                                [img]="img"></calsoft-price-plan>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.customField10 == 'Type 8'">
                            <calsoft-image-zigzag-text fxFlex="100" [data]="data" [img]="item.images" [index]="i"
                                [item]="item" [img]="img"></calsoft-image-zigzag-text>
                        </div>

                    </div>
                </div>


                <div *ngIf="item.customField10 == 'Type 9' && item.type=='image'">

                    <div fxLayout="row" fxLayout.lt-sm="column" class="container">
                        <div fxFlex="50">
                            <div class="title">
                                <calsoft-editor-text [data]="data" [text]="item.title" [id]="item.customField35"
                                    [align]="item.customField34" [ngStyle.gt-md]="{'font-size': item.customField17 }"
                                    [ngStyle.lt-md]="{
                                
                                'font-size': item.customField18
                            }">
                                </calsoft-editor-text>
                            </div>

                            <div style="text-align: left;" class="subDefinitions">
                                <calsoft-editor-text [data]="data" [text]="item.customField7" [id]="item.customField35"
                                    [align]="item.customField34">
                                </calsoft-editor-text>
                            </div>

                            <div class="padding-10">

                                <div fxLayout="row">
                                    <div fxFlex="5" fxFlex.xs="10">
                                        <mat-icon class="maticoncss">check</mat-icon>
                                    </div>
                                    <div fxFlex>
                                        <h3 class="font-h3">
                                            <calsoft-editor-text [data]="data" [id]="item.customField35"
                                                [text]="item.customField11" [align]="item.customField34">
                                            </calsoft-editor-text>
                                        </h3>
                                    </div>
                                </div>

                                <div fxLayout="row">
                                    <div fxFlex="5" fxFlex.xs="10">
                                        <mat-icon class="maticoncss">check</mat-icon>
                                    </div>
                                    <div fxFlex>
                                        <h3 class="font-h3">
                                            <calsoft-editor-text [data]="data" [id]="item.customField35"
                                                [text]="item.customField12" [align]="item.customField34">
                                            </calsoft-editor-text>
                                        </h3>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div fxFlex="50">
                            <calsoft-image-with-text-type [MobfontSize]="'28px'" fxFlex="100" [img]="item.images"
                                [index]="i" [item]="item">
                            </calsoft-image-with-text-type>
                        </div>
                    </div>



                </div>

                <div *ngIf="item.customField10 == 'Type 10' && item.type=='image'">
                    <calsoft-image-type10 fxFlex="100" [img]="item.images" [index]="i" [item]="item" [img]="img">
                    </calsoft-image-type10>
                </div>

                <div *ngIf="item.customField10 == 'Type 11' && item.type=='image'">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-sm="column" fxFlex="100">
                        <div fxLayout="column" fxLayoutAlign="center center"
                            style="padding-top:50px;padding:0px 50px ;">
                            <div [style.color]="item.customField7" [style.font-size]="item.customField18"
                                [ngStyle.lt-md]="{ 'font-size': item.customField17 }"
                                style="text-align: center;line-height: 1.2em;" [innerHTML]="item.customField5"></div>
                            <div style="font-size: 24px;text-align: center;color: #005941;padding-top: 25px;"
                                [ngStyle.lt-md]="{ 'font-size': '18px' }" [innerHTML]="item.customField29"></div>
                            <div fxLayout="row" fxLayoutGap="10px" style="padding-top: 35px;"
                                [ngStyle.lt-md]="{ 'padding-top': '15px', 'padding-bottom':'15px' }">
                                <!-- <button class="primary padding-tb10" (click)="link(item.images[0].customField4)"
                             mat-flat-button [style.font-size]="item.images[0].customField7" 
                             
                                 style="border-radius: 60px;color: white;height: 50px;"
                                  [style.background-color]="item.customField8">{{item.customField12}}</button>
                            <button class="primary padding-tb10" (click)="link(item.images[0].customField5)" 
                            mat-flat-button [style.font-size]="item.images[0].customField7"  
                                 style="border-radius: 60px;color: white;" 
                                 [style.background-color]="item.customField22">{{item.customField34}}</button> -->

                                <ng-container *ngIf="img.customField12">
                                    <button class="curved-button" (click)="link(item.images[0].customField4)"
                                        [style.padding]="'20px'" [style.background-color]="item.customField8">
                                        {{item.customField12}}
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="img.customField34">
                                    <button class="curved-button" (click)="link(item.images[0].customField5)"
                                        [style.padding]="'20px'" [style.background-color]="item.customField22">
                                        {{item.customField34}}
                                    </button>
                                </ng-container>
                            </div>


                        </div>
                        <div fxFlex="50">
                            <calsoft-lookback fxFlex="100" [data]="data" [index]="i" [item]="item" [img]="item.images"
                                [type]="'new1'">
                            </calsoft-lookback>
                        </div>
                    </div>
                </div>

                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='textWithImage' &&  item.customField20=='5'">
                    <calsoft-bannertextwithimage [data]="data" [item]="item"></calsoft-bannertextwithimage>
                </div>

                <ng-container *ngIf="(item.type=='textWithImage' &&  item.customField20!='5') &&router.url!='/blog'">


                    <div class=" outer-wrapper background-wrapper" [style.background-color]="item.customField27">
                        <div class=" outer-wrapper background-wrapper">
                            <div [class.space]="item.customField8.length>0" [ngClass]="{'margin-t10': i > 0}"
                                [class.container]="item.customField20 !== '2' && item.customField20 !== '3' && item.customField20 !== '4'"
                                class="container inner-wrapper" fxLayout.lt-md="column"
                                [style.color]="item.customField28"
                                [fxLayout.gt-md]="item.customField3=='right'?'row':'row-reverse'"
                                [fxLayoutGap]="item.gapBetweenImage">

                                <div fxFlex.gt-md="50" fxFlex.lt-md fxLayout="row"
                                    [style.align-items]="item.customField30 || 'center'">

                                    <div style="width:100%; word-break: break-all;"
                                        [ngClass]="{'container': item.customField20 === '4'}" fxLayoutGap="5px">
                                        <div *ngIf="item.images[0].customField33 != 2">
                                            <div *ngIf="item.customField20==1 ||  item.customField20 == '3'"
                                                fxLayout="row" fxLayoutAlign="start center">
                                                <calsoft-textwithimage [item]="item" [data]="data">
                                                </calsoft-textwithimage>
                                            </div>

                                            <span *ngIf="stripHtmlTags(item?.title) != ''">
                                                <div *ngIf="item.customField20 == 0 || item.customField20 == '2' || item.customField20 == '4'"
                                                    fxLayout="row" fxLayoutAlign="start center">
                                                    <h2 class="textwithImage" style="word-break: break-word;"
                                                        [style.color]="item.customField28"
                                                        [ngStyle]="{'font-family': item.fontFamily}">
                                                        <calsoft-editor-text [data]="data" [id]="item.customField35"
                                                        [ngStyle.gt-md]="{'font-size': item.customField18 }"
                                                        [ngStyle.lt-md]="{
                                                            
                                                            'font-size': item.customField17
                                                        }"
                                            
                                                            [text]="item?.title" [align]="item.customField34">
                                                        </calsoft-editor-text>
                                                    </h2>
                                                </div>
                                            </span>


                                        </div>
                                        <div style="width:100% ;" fxLayoutGap="10px"
                                            [ngStyle]="{'font-family': item.fontFamily || 'Poppins,sans-serif!important'}">
                                            <div >
                                                <calsoft-editor-text [data]="data" [matIcon]="item.customField4"
                                                [ngStyle.gt-md]="{'font-size': item.customField37 }"
                                                [ngStyle.lt-md]="{
                                                    
                                                    'font-size': item.customField38
                                                }"
                            
                                                  [text]="item?.customField1"
                                                    [ngStyle]="{'font-family': item.fontFamily || 'Poppins,sans-serif!important'}"
                                                    [align]="item.customField34" [id]="item.customField35">
                                                </calsoft-editor-text>
                                            </div>

                                            <div *ngIf="item.images[0].customField26==1">
                                                <!-- display in the first row of the table as headers -->
                                                <!--<table style="width:100%; border-collapse: collapse;">
                                                <thead>
                                                  <tr style="background-color: #f3f3f3;">
                                                    <th *ngFor="let field of item.images[0].customField22.split(',')" style="padding: 10px; text-align: left; border: 1px solid #ddd;">
                                                      <strong>{{ field }}</strong>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td *ngFor="let data of item.images[0].customField24.split(','); let i = index" style="padding: 10px; text-align: left; border: 1px solid #ddd;">
                                                      {{ data }}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                            </table> -->

                                                <!-- data to display in two columns -->
                                                <table style="width:100%; border-collapse: collapse;">

                                                    <tbody>
                                                        <!-- Iterate over fields and data to display in two columns -->
                                                        <tr *ngFor="let field of item.images[0].customField22.split(','); let i = index">
                                                          <td
                                                            style="padding: 10px; text-align: left; border: 1px solid #ddd; background-color: #f3f3f3;">
                                                            <strong>
                                                              <calsoft-editor-text
                                                                [data]="data"
                                                                [matIcon]="item.customField4"
                                                                [ngStyle.gt-md]="{'font-size': item.customField37}"
                                                                [ngStyle.lt-md]="{'font-size': item.customField38}"
                                                                [text]="field"
                                                                [ngStyle]="{'font-family': item.fontFamily || 'Poppins, sans-serif!important'}"
                                                                [align]="item.customField34"
                                                                [id]="item.customField35">
                                                              </calsoft-editor-text>
                                                            </strong>
                                                          </td>
                                                          <td
                                                            style="padding: 10px; text-align: left; border: 1px solid #ddd;">
                                                            <calsoft-editor-text
                                                              [data]="data"
                                                              [matIcon]="item.customField4"
                                                              [ngStyle.gt-md]="{'font-size': item.customField37}"
                                                              [ngStyle.lt-md]="{'font-size': item.customField38}"
                                                              [text]="item.images[0].customField24.split(',')[i]"
                                                              [ngStyle]="{'font-family': item.fontFamily || 'Poppins, sans-serif!important'}"
                                                              [align]="item.customField34"
                                                              [id]="item.customField35">
                                                            </calsoft-editor-text>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                      
                                                </table>

                                            </div>


                                        </div>

                                        <div fxLayout="row"
                                            *ngIf="item.images[0].customField33 != 1 && item.images[0].customField33 != 2">


                                            <button style="color: white;margin-top: 15px"
                                                [style.border-radius]="item.customField11"
                                                *ngIf="item.categoryId6==0  && item.customField5.length > 0"
                                                (click)="goToCategories(item.customField2)" mat-flat-button
                                                class="primary">{{item.customField5}}</button>
                                            <button style="color: white;margin-top: 15px"
                                                [style.border-radius]="item.customField11"
                                                *ngIf="item.categoryId6==1   && item.customField5.length > 0"
                                                (click)="link(item.customField2)" mat-flat-button
                                                class="primary">{{item.customField5}}</button>
                                            <button style="color: white;margin-top: 15px"
                                                [style.border-radius]="item.customField11"
                                                *ngIf="item.categoryId6==2   && item.customField5.length > 0"
                                                (click)="dynamicFormPopup(item.customField2)" mat-flat-button
                                                class="primary">{{item.customField5}}</button>

                                        </div>

                                        <div fxLayout="row" *ngIf="item.images[0].customField33 == '1'"
                                            fxLayoutGap="20px" style="margin-top: 20px;">

                                            <button style="color: white;margin-top: 15px"
                                                [style.border-radius]="item.customField11"
                                                [style.background-color]="item.images[0].customField18"
                                                *ngIf="item.categoryId6==0" (click)="goToCategories(item.customField2)"
                                                mat-flat-button [style.padding-left]="item.images[0].customField20"
                                                [style.padding-right]="item.images[0].customField20"
                                                [style.height]="item.images[0].customField17"
                                                class="primary">{{item.customField5}}</button>

                                            <button style="color: white;margin-top: 15px"
                                                [style.border-radius]="item.customField11" *ngIf="item.categoryId6==1"
                                                [style.padding-left]="item.images[0].customField20"
                                                [style.padding-right]="item.images[0].customField20"
                                                (click)="link(item.customField2)"
                                                [style.background-color]="item.images[0].customField18" mat-flat-button
                                                class="primary"
                                                [style.height]="item.images[0].customField17">{{item.customField5}}</button>

                                            <button style="color: white;margin-top: 15px"
                                                [style.border-radius]="item.customField11" *ngIf="item.categoryId6==0"
                                                [style.height]="item.images[0].customField17"
                                                (click)="openInNewWindow(item.images[0].customField22)" mat-flat-button
                                                [style.background-color]="item.images[0].customField19"
                                                [style.padding-left]="item.images[0].customField20"
                                                [style.padding-right]="item.images[0].customField20"
                                                class="primary">{{item.images[0].customField16}}</button>

                                            <button style="color: white;margin-top: 15px"
                                                [style.border-radius]="item.customField11" *ngIf="item.categoryId6==1"
                                                [style.padding-left]="item.images[0].customField20"
                                                [style.padding-right]="item.images[0].customField20"
                                                (click)="openInNewWindow(item.images[0].customField22)"
                                                [style.background-color]="item.images[0].customField19" mat-flat-button
                                                class="primary"
                                                [style.height]="item.images[0].customField17">{{item.images[0].customField16}}</button>

                                        </div>
                                        <div>

                                        </div>
                                    </div>

                                </div>
                                <div class="images" *ngFor="let img of item.images | slice:0:1" fxFlex.gt-md="50"
                                    fxFlex.lt-md fxLayout="column" fxLayoutAlign="start stretch">


                                    <ng-container *ngIf="img.type==2">
                                        <div>
                                            <div style="width: 100%;height:500px" ngStyle.lt-md="height:100%">
                                                <iframe style="width: 100%; height: 100%;"
                                                    [src]="youtubeUrl+img.img | safe: 'resourceUrl'"></iframe>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="img.type==1">
                                        <div contentAnim [aniName]="item.customField36"
                                            [ngClass]="{'cardzoom': img.customField9 === '1'}">
                                            <!-- <img style="width:100% ;" [style.height]="item.height" 
                                        [style.margin-right]="item.gapBetweenImage"
                                        [ngClass]="{'container': item.customField20 === '4'}"
                                        [ngStyle.lt-md]="{'height': item.heightMob}"
                                        (click)="img.navigation==0?goToCategories(img.catId):link(img.navigatedUrl)"
                                        [src]="serverPath+img.img" [alt]="img.altImage || 'images' "> -->
                                            <img style="width:100% ;" [style.height]="item.height"
                                                [style.margin-right]="item.gapBetweenImage"
                                                [ngStyle.lt-md]="{'height': item.heightMob}"
                                                (click)="img.navigation==0?goToCategories(img.catId):link(img.navigatedUrl)"
                                                [src]="serverPath+img.img" [alt]="img.altImage || 'images' ">
                                        </div>

                                    </ng-container>

                                </div>
                            </div>

                        </div>

                    </div>



                </ng-container>


                <div [ngClass]="i>0 ? 'margin-t11' : ''" *ngIf="item.type=='emiCalculator'">
                    <calsoft-emi-calculator fxFlex="100" [img]="item.images" [index]="i" [item]="item" [img]="img">
                    </calsoft-emi-calculator>
                </div>

                <ng-container *ngIf="(item.type=='textWithImage' &&  item.customField20!='5')&&router.url=='/blog'">
                    <div class="container" [ngClass]="i>0 ? 'margin-t11' : ''" fxLayout.lt-md="column"
                        [fxLayout.gt-md]="item.customField3=='right'?'row':'row-reverse'" fxLayoutGap="5px">
                        <div fxFlex.gt-md="70" fxFlex.lt-md fxLayout="row">

                            <div fxFlexAlign="center" fxLayoutGap="5px">
                                <div *ngIf="item.title!=0">
                                    <div *ngIf="item.customField20==1" fxLayout="row" fxLayoutAlign="start center">
                                        <calsoft-textwithimage [data]="data" [item]="item"></calsoft-textwithimage>
                                    </div>
                                    <div *ngIf="item.customField20==0" fxLayout="row" fxLayoutAlign="start center">
                                        <h2 class="textwithImage">
                                            <calsoft-editor-text [data]="data" [id]="item.customField35"
                                            [ngStyle.gt-md]="{'font-size': item.customField18 }"
                                            [ngStyle.lt-md]="{
                                                
                                                'font-size': item.customField17
                                            }"
                                
                                                [text]="item?.title" [align]="item.customField34">
                                            </calsoft-editor-text>
                                        </h2>
                                    </div>
                                </div>
                                <div>
                                    <calsoft-editor-text [data]="data"  [id]="item.customField35"
                                    [ngStyle.gt-md]="{'font-size': item.customField37 }"
                                    [ngStyle.lt-md]="{
                                        
                                        'font-size': item.customField38
                                    }"
                
                                        [text]="item?.customField1" [align]="item.customField34">
                                    </calsoft-editor-text>
                                </div>
                                <div fxLayout="row">
                                    {{item.categoryId6}}
                                    <button style="color: white;"
                                        *ngIf="item.categoryId6==0 && item.customField5.length > 0"
                                        (click)="goToCategories(item.customField2)" mat-flat-button
                                        class="primary">{{item.customField5}}</button>
                                    <button style="color: white;" *ngIf="item.categoryId6==1"
                                        (click)="link(item.customField2)" mat-flat-button
                                        class="primary">{{item.customField5}}</button>

                                </div>
                            </div>
                        </div>
                        <div class="images" *ngFor="let img of item.images | slice:0:1" fxFlex.gt-md="30" fxFlex.lt-md
                            fxLayout="column" fxLayoutAlign="start center">

                            <ng-container *ngIf="img.type==2">
                                <div>
                                    <div style="width: 100%;">
                                        <iframe style="width: 100%;height:500px" ngStyle.lt-md="height:100%"
                                            [ngSrc]="youtubeUrl+img.img | safe: 'resourceUrl'"></iframe>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="img.type==1">
                                <img (click)="img.navigation==0?goToCategories(img.catId):link(img.navigatedUrl)"
                                    style=" width: 350px; max-height: 545px;" [ngSrc]="serverPath+img.img"
                                    [alt]="img.altImage ||'images'">
                            </ng-container>
                        </div>

                    </div>

                </ng-container>

                <div [ngClass]="i>0 ? 'margin-t11' : ''">


                    <ng-container *ngIf="item.type=='freeText'">
                        <div>
                            <div [class.space]="item.customField8.length>0" class="bg-image"
                                [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'"
                                [style.background-color]="item.customField8"
                                [ngClass]="item.customField23 ? 'centercss' :'' " fxLayout="row" fxLayoutGap="5px"
                                [style.border-radius]="item.customField24"
                                [fxFlex]="(item.customField23) ? item.customField23 : 100"
                                [fxFlex.lt-md]="(item.customField28) ? item.customField28 : ''">


                                <div fxFlex="100" fxLayout="row" class="container">
                                    <div fxFlex="100" fxLayout="row">
                                        <div fxFlex="100" fxLayout="column">
                                            <div *ngIf="item.customField25  !=1 || item.customField25 != '1'  ">
                                                <div *ngIf="item.customField27=='Center'" fxFlex="100"
                                                    fxLayoutAlign="center center"
                                                    [ngStyle]="{'font-family': item.fontFamily || 'cursive', 'color': item.customField26 || 'black'}"
                                                    contentAnim [aniName]="item.customField7"
                                                    [style.font-size]="item.customField18" [ngStyle.lt-md]="{ 'font-size': item.customField17,
                  'font-family': item.fontFamily || 'cursive'
                 }" [style.font-weight]="item.customField29" class="textwithImage">
                                                    <calsoft-editor-text [data]="data" [id]="item.customField35"
                                                        [text]="item?.title" [align]="item.customField34">
                                                    </calsoft-editor-text>
                                                </div>

                                                <div *ngIf="item.customField27=='Left'" fxFlex="100"
                                                    fxLayoutAlign="start center"
                                                    [ngStyle]="{'font-family': item.fontFamily || 'cursive', 'color': item.customField26 || 'black'}"
                                                    contentAnim [aniName]="item.customField7"
                                                    [style.font-size]="item.customField18" [ngStyle.lt-md]="{ 'font-size': item.customField17,
              'font-family': item.fontFamily || 'cursive'
             }" [style.font-weight]="item.customField29" class="textwithImage">
                                                    <calsoft-editor-text [data]="data" [id]="item.customField35"
                                                        [text]="item?.title" [align]="item.customField34">
                                                    </calsoft-editor-text>
                                                </div>
                                            </div>


                                            <div *ngIf="item.categoryId6==1">

                                                <div fxFlex="100" fxLayoutAlign="start center">
                                                    <calsoft-editor-text [data]="data" [id]="item.customField35"
                                                        [text]="item?.customField1" [align]="item.customField34"
                                                        [ngStyle.gt-md]="{'font-size': item.customField18 }"
                                                        [ngStyle.lt-md]="{
                                                        
                                                        'font-size': item.customField17
                                                    }">
                                                    </calsoft-editor-text>
                                                </div>

                                            </div>

                                            <div *ngIf="item.categoryId6==0">
                                                <div fxFlex="100" fxLayoutAlign="center center">
                                                    <calsoft-editor-text [data]="data" [id]="item.customField35"
                                                        [text]="item?.customField1"
                                                        [ngStyle.gt-md]="{'font-size': item.customField39 }"
                                                        [ngStyle.lt-md]="{
                                                    
                                                    'font-size': item.customField40
                                                }" [align]="item.customField34">
                                                    </calsoft-editor-text>
                                                </div>

                                            </div>
                                        </div>

                                        <div fxLayoutAlign="center center"
                                            *ngIf="item.customField30 && item.customField30.length>0">
                                            <a mat-raised-button href="{{item.customField32}}"
                                                [style.background-color]="item.customField31" target="_blank"
                                                style="color: white;border-radius: 30px;">{{item.customField30}}
                                                <mat-icon *ngIf="item.customField33">{{item.customField33}}</mat-icon>
                                            </a>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                </div>


            </div>

            <div *ngIf="type==1">
                <div class="search-results" infiniteScroll [infiniteScrollDistance]="100" [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()">

                </div>

                <div fxLayout=row fxLayoutAlign="center center" *ngIf="spinner !=false">
                    <mat-progress-spinner *ngIf="page <pageSize"></mat-progress-spinner>
                </div>
            </div>

            <div *ngIf="type==2&&pageNated">
                <div class="search-results" infiniteScroll [infiniteScrollDistance]="100" [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()">

                </div>

                <div fxLayout=row fxLayoutAlign="center center" *ngIf="spinner !=false">
                    <mat-progress-spinner *ngIf="page <pageSize"></mat-progress-spinner>
                </div>
            </div>


            <div *ngIf="data.layout=='twentyseven'">
                <calsoft-footer *ngIf="data.footerLayout == 1" [data]="data"> </calsoft-footer>
                <calsoft-footer4 *ngIf="data.footerLayout == 5" [data]="data"></calsoft-footer4>
                <calsoft-footer3 *ngIf="data.footerLayout == 4" [data]="data"></calsoft-footer3>
                <calsoft-footer2 *ngIf="data.footerLayout == 3" [data]="data"></calsoft-footer2>
                <calsoft-footer1 *ngIf="data.footerLayout == 2" [data]="data"></calsoft-footer1>
                <calsoft-footer5 *ngIf="data.footerLayout == 6" [data]="data"></calsoft-footer5>
            </div>

            <!-- <calsoft-footer-new-type [data]="data"></calsoft-footer-new-type> -->

        </div>
    </div>
</div>


<ng-template #image>

    <div class="index-head popupTitle" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <!-- <span>Withdraw</span> -->
        </div>
        <button mat-icon-button matDialogClose>
            <mat-icon style="font-size: 18px;">
                close
            </mat-icon>
        </button>
    </div>

    <div class="padding-index">
        <img [src]="imageSrc" width="100%">
    </div>

</ng-template>



<!-- <calsoft-imagecircleanimate></calsoft-imagecircleanimate> -->